<template lang="">
  <div>
    <h6 class="heading-small text-muted mb-4">ข้อมูลประเภทปัญหา</h6>
    <div class="row">
      <div class="col-md-4 offset-md-4">
        <div class="mb-4">
          <label class="form-label">ชื่อ</label>
          <base-input name="name" placeholder="ชื่อ" v-model="item.name" :rules="{required: true}"></base-input>
        </div>
        <div class="mb-4">
          <label class="form-label optional">หมายเหตุ</label>
          <b-form-textarea name="remark" placeholder="หมายเหตุ" v-model="item.remark" rows="3" max-rows="5"></b-form-textarea>
        </div>
      </div>
      <div class="col-sm-12">
        <hr class="my-4">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'issue_type-view-form-issue_type',
  props: [
    'item'
  ]
}
</script>
